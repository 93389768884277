import { RenderElementProps } from "components/ManualBuilder/types";
import { ElementRenderer } from "../elementMap";

export const paragraphElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    <p {...attributes}>{children}</p>;

export const ulElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <ul {...attributes} className="pl-10 my-4 list-disc">
        {children}
      </ul>
    );

export const olElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <ol {...attributes} className="pl-10 my-4 list-decimal">
        {children}
      </ol>
    );

export const liElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    <li {...attributes}>{children}</li>;
