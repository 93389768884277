import { ReactEditor } from "slate-react";
import {
  RenderElementProps,
  MessageBoxElement,
} from "components/ManualBuilder/types";
import { CustomEditor } from "components/ManualBuilder/types";
import {
  getMessageBoxStyle,
  getParentMessageBox,
  getPictogramUrl,
} from "./utils";
import { ElementRenderer } from "../elementMap";

export const messageBoxElement: ElementRenderer =
  () =>
  ({ attributes, children, element }: RenderElementProps) => {
    const messageElement = element as MessageBoxElement;
    const style = getMessageBoxStyle(messageElement.boxType);
    const pictogramUrl = getPictogramUrl(messageElement.boxType);

    return (
      <div {...attributes} className={style.box}>
        <img
          src={pictogramUrl}
          alt={`${messageElement.boxType} pictogram`}
          className={style.pictogram}
          contentEditable={false}
        />
        <div className={style.wrapper}>{children}</div>
      </div>
    );
  };

export const titleElement: ElementRenderer =
  (editor: CustomEditor) =>
  ({ attributes, children, element }: RenderElementProps) => {
    const path = ReactEditor.findPath(editor, element);
    const boxType = getParentMessageBox(editor, path);
    const style = getMessageBoxStyle(boxType);
    return (
      <div {...attributes} className={style.header}>
        {children}
      </div>
    );
  };

export const contentElement: ElementRenderer =
  (editor: CustomEditor) =>
  ({ attributes, children, element }: RenderElementProps) => {
    const path = ReactEditor.findPath(editor, element);
    const boxType = getParentMessageBox(editor, path);
    const style = getMessageBoxStyle(boxType);
    return (
      <div {...attributes} className={style.content}>
        {children}
      </div>
    );
  };
