import { useCallback } from "react";
import { Editor, Element as SlateElement, Node, Transforms } from "slate";
import isHotkey from "is-hotkey";
import { HOTKEYS } from "components/ManualBuilder/types";
import { toggleMark } from "components/ManualBuilder/components/toolbar";

export const useKeyboardHandlers = (editor: Editor) => {
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Handle hotkeys
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }

      // Handle Enter key
      if (event.key === "Enter" && !event.shiftKey) {
        const { selection } = editor;

        if (selection) {
          // Check if we're in a list item
          const [listItem] = Editor.nodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === "li",
          });

          if (listItem) {
            event.preventDefault();
            const [node] = listItem;

            // Check if the current list item is empty
            const isEmptyListItem = Node.string(node).length === 0;

            if (isEmptyListItem) {
              // Convert to normal paragraph
              Transforms.unwrapNodes(editor, {
                match: (n) =>
                  !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  (n.type === "ul" || n.type === "ol"),
                split: true,
              });
              Transforms.setNodes(editor, { type: "paragraph" });
            } else {
              // Insert a new list item
              editor.insertBreak();
            }
            return;
          }

          // Default behavior for non-list blocks: reset to normal paragraph
          event.preventDefault();
          editor.insertBreak();
          Transforms.setNodes(
            editor,
            { type: "paragraph" },
            {
              match: (n) =>
                SlateElement.isElement(n) && Editor.isBlock(editor, n),
            }
          );
        }
      }

      // Handle tab key for table navigation
      if (event.key === "Tab") {
        event.preventDefault();
        const { selection } = editor;

        if (selection) {
          const [table] = Editor.nodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === "table",
          });

          if (table) {
            const [cell] = Editor.nodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === "table-cell",
            });

            if (cell) {
              const [, cellPath] = cell;
              const nextPath = [
                ...cellPath.slice(0, -1),
                cellPath[cellPath.length - 1] + 1,
              ];

              try {
                const nextCell = Editor.node(editor, nextPath);
                if (nextCell) {
                  const point = Editor.start(editor, nextPath);
                  Transforms.select(editor, point);
                }
              } catch (error) {
                // If next cell doesn't exist, try moving to the next row
                try {
                  const nextRowPath = [
                    ...cellPath.slice(0, -2),
                    cellPath[cellPath.length - 2] + 1,
                    0,
                  ];
                  const nextRowCell = Editor.node(editor, nextRowPath);
                  if (nextRowCell) {
                    const point = Editor.start(editor, nextRowPath);
                    Transforms.select(editor, point);
                  }
                } catch (error) {
                  // Do nothing if we're at the last cell
                }
              }
            }
          }
        }
      }
    },
    [editor]
  );

  return onKeyDown;
};
