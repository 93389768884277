import React from "react";
import { ReactElement } from "react";
import { classNames } from "utils/classNames";

import { Project } from "types";
import { useTranslation } from "react-i18next";
import { TrackingContext } from "context/Tracking";
import { Directive } from "pages/Backoffice/Questionaire/types";

interface Tab {
  name: string;
  content: (project: Project) => ReactElement;
}

const tabs: Tab[] = [
  {
    name: "details.product.tab_name",
    content: (p) => <BaseInformationTab project={p} />,
  },
];

const BaseInformationTab = (props: { project: Project }) => {
  const { t } = useTranslation();

  const isAnyOtherThanMrlExit =
    props.project.directives.some((dir) => dir.name !== Directive.mrl) &&
    props.project.projectdata.category === "exit";

  const category = isAnyOtherThanMrlExit
    ? "exit_" + props.project.directives[0].name
    : props.project.projectdata.category;

  return (
    <div className="mt-6">
      <div className="py-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("details.product.label")}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {t("details.product.subline")}
        </p>
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.product.name")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.projectdata.product_name}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.product.typ")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.projectdata.type}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.product.category")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {t(`common.selector.product_category.${category}`)}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.product.serial")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.projectdata.serial_number}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.product.description")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.projectdata.description}
          </dd>
        </div>
        {props.project.languages?.length && (
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              {t("details.product.languages")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {props.project.languages
                ?.map(({ code }) => t(`languages.${code}`))
                .join(", ")}
            </dd>
          </div>
        )}
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Certain Resource Name{" "}
            <a className="text-cyan-600 text-xs cursor-pointer italic">(CRN)</a>
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.projectdata.crn}
          </dd>
        </div>
      </dl>
      {/*  */}
      <div className="py-5">
        <div className="border-t border-gray-200 py-2"></div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("details.engineer.label")}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {t("details.engineer.subline")}
        </p>
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.engineer.name")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.engineer.name}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("details.engineer.mail")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {props.project.engineer.email_adress}
          </dd>
        </div>
      </dl>
    </div>
  );
};

const ProjectDetailTabsHeader = (props: {
  currentTab: Tab;
  tabs: Tab[];
  onChange: (tab: Tab) => void;
}) => {
  const { t } = useTranslation();
  const { track } = React.useContext(TrackingContext);
  const changeHandler = (tab: Tab) => {
    props.onChange(tab);

    track("change detail tab", {
      tab,
    });
  };

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="mx-auto">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <span
                onClick={() => changeHandler(tab)}
                key={tab.name}
                className={classNames(
                  props.currentTab?.name === tab.name
                    ? "border-cyan-600 text-gray-900"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {t(tab.name)}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface ProjectDetailTabProps {
  project: Project;
}

export const ProjectDetailTabs = (props: ProjectDetailTabProps) => {
  const [currentTab, setCurrentTab] = React.useState<Tab>(tabs[0]);

  return (
    <div>
      <ProjectDetailTabsHeader
        tabs={tabs}
        currentTab={currentTab}
        onChange={(tab: Tab) => setCurrentTab(tab)}
      />
      {currentTab.content(props.project)}
    </div>
  );
};
