import {
  CustomElement,
  RenderElementProps,
  CustomEditor,
} from "components/ManualBuilder/types";
import {
  h1Element,
  h2Element,
  h3Element,
  h4Element,
} from "./headings/HeadingElements";
import {
  paragraphElement,
  ulElement,
  olElement,
  liElement,
} from "./text/TextElements";
import {
  tableElement,
  tableRowElement,
  tableCellElement,
} from "./table/TableElements";
import { imageElement } from "./image/ImageElement";
import {
  messageBoxElement,
  titleElement,
  contentElement,
} from "./messageBox/MessageBoxElements";
import { projectDataElement } from "./projectData/ProjectDataElement";

export type ElementRenderer = (
  editor: CustomEditor
) => (props: RenderElementProps) => JSX.Element;

export const elementMap: Record<CustomElement["type"], ElementRenderer> = {
  h1: h1Element,
  h2: h2Element,
  h3: h3Element,
  h4: h4Element,
  paragraph: paragraphElement,
  image: imageElement,
  ul: ulElement,
  ol: olElement,
  li: liElement,
  table: tableElement,
  "table-row": tableRowElement,
  "table-cell": tableCellElement,
  "message-box": messageBoxElement,
  title: titleElement,
  content: contentElement,
  "project-data": projectDataElement,
};
