import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/solid";

import i18n from "i18n";
import { Languages } from "types";
import { Feature, FeatureFlagContext } from "context/FeatureFlag";

const flagsCode: Record<Languages, string> = {
  [Languages.german]: "de",
  [Languages.english]: "gb",
  [Languages.french]: "fr",
  [Languages.spanish]: "es",
  [Languages.italian]: "it",
  [Languages.polish]: "pl",
  [Languages.swedish]: "se",
  [Languages.hungarian]: "hu",
  [Languages.slovakian]: "sk",
  [Languages.slovenian]: "si",
};

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const [langCode] = i18n.language.split("-");
  const { isFeatureEnabled } = useContext(FeatureFlagContext);
  const [showOptions, setShowOptions] = React.useState(false);
  const [language, setLanguage] = React.useState(langCode as Languages);
  const [languageOptions, setLanguageOptions] = React.useState<Languages[]>([
    Languages.german,
    Languages.english,
  ]);

  useEffect(() => {
    if (isFeatureEnabled?.(Feature.SecondBatchLanguages)) {
      setLanguageOptions([
        Languages.german,
        Languages.english,
        Languages.french,
        Languages.spanish,
        Languages.italian,
        Languages.polish,
        Languages.swedish,
        Languages.hungarian,
        Languages.slovakian,
        Languages.slovenian,
      ]);
    }
  }, [isFeatureEnabled]);

  useEffect(() => {
    setLanguage(langCode as Languages);
  }, [langCode]);

  return (
    <div className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm  bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cyan-500 p-2 flex items-center"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setShowOptions(!showOptions)}
        >
          <span className="pr-2">
            <img
              src={`https://flagcdn.com/16x12/${flagsCode[language]}.png`}
              srcSet={`https://flagcdn.com/32x24/${flagsCode[language]}.png 2x,
    https://flagcdn.com/48x36/${flagsCode[language]}.png 3x`}
              width="16"
              height="12"
              alt="Ukraine"
            ></img>
          </span>
          {t(`languages.${language}`)}
          <ChevronDownIcon
            className={`h-5 w-5 transition-transform -mr-1 ml-2 ${
              showOptions ? "transform rotate-180" : ""
            }`}
          />
        </button>
      </div>

      {showOptions && (
        <div className="z-50 origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 bottom-12">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {languageOptions.map((lang) => (
              <li
                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer flex items-center"
                role="menuitem"
                onClick={() => {
                  i18n.changeLanguage(lang);
                  setLanguage(lang);
                  setShowOptions(false);
                }}
              >
                <span className="pr-2">
                  <img
                    src={`https://flagcdn.com/16x12/${flagsCode[lang]}.png`}
                    srcSet={`https://flagcdn.com/32x24/${flagsCode[lang]}.png 2x,
    https://flagcdn.com/48x36/${flagsCode[lang]}.png 3x`}
                    width="16"
                    height="12"
                    alt="Ukraine"
                  ></img>
                </span>

                {t(`languages.${lang}`)}
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
