import React, { useCallback } from "react";

import { RenderElementProps } from "components/ManualBuilder/types";
import { Element, Leaf } from "components/ManualBuilder/components/elements";

export const useEditorRenderers = () => {
  const renderElement = useCallback(
    (props: RenderElementProps) => React.createElement(Element, props),
    []
  );

  const renderLeaf = useCallback(
    (props: any) => React.createElement(Leaf, props),
    []
  );

  return {
    renderElement,
    renderLeaf,
  };
};
