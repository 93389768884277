import { type SlateToDomConfig } from "@slate-serializers/dom";
import { slateToHtmlConfig } from "@slate-serializers/html";
import { Element, Text } from "domhandler";
import {
  MessageBoxElement,
  MessageBoxType,
  ProjectDataElement,
} from "../types";
import { Project } from "types";

const styles = {
  warning: {
    box: "message-box box-warning bg-yellow-50 border border-yellow-400 rounded-md my-4 overflow-hidden flex",
    pictogram: "message-picto",
    content: "flex-grow",
    header: "bg-yellow-100 px-4 py-2 font-semibold text-yellow-800",
    body: "p-4",
  },
  info: {
    box: "message-box box-info bg-blue-50 border border-blue-400 rounded-md my-4 overflow-hidden flex",
    pictogram: "message-picto",
    content: "flex-grow",
    header: "bg-blue-100 px-4 py-2 font-semibold text-blue-800",
    body: "p-4",
  },
  danger: {
    box: "message-box box-danger bg-red-50 border border-red-400 rounded-md my-4 overflow-hidden flex",
    pictogram: "message-picto",
    content: "flex-grow",
    header: "bg-red-100 px-4 py-2 font-semibold text-red-800",
    body: "p-4",
  },
};

const picto = {
  warning: `${process.env.RAZZLE_TECH_CONTENT_URL}/pictograms/svgs/W001`,
  danger: `${process.env.RAZZLE_TECH_CONTENT_URL}/pictograms/svgs/W001`,
  info: `${process.env.RAZZLE_TECH_CONTENT_URL}/pictograms/svgs/M001`,
};

export const getHtmlTransforms: (project?: Project) => SlateToDomConfig = (
  project
) => ({
  ...slateToHtmlConfig,
  elementMap: {
    ...slateToHtmlConfig.elementMap,
    table: "table",
    "table-row": "tr",
    "table-cell": "td",
    thead: "thead",
    th: "th",
    tbody: "tbody",
  },
  elementTransforms: {
    ...slateToHtmlConfig.elementTransforms,
    "table-cell": ({ node, children }) => {
      return new Element(node.isHeader ? "th" : "td", {}, children);
    },
    title: ({ children }) => {
      return new Element("p", { class: "message-title" }, children);
    },
    content: ({ children }) => {
      return new Element("p", { class: "message-content" }, children);
    },
    image: ({ node }) => {
      return new Element("img", {
        src: node.url,
      });
    },
    "message-box": ({ node, children }) => {
      const boxNode = node as MessageBoxElement;
      const boxType = (boxNode?.boxType as MessageBoxType) || "warning";

      const pictogramElement = new Element("img", {
        src: picto[boxType],
        class: styles[boxType].pictogram,
        alt: `${boxType} pictogram`,
      });

      const contentWrapper = new Element(
        "div",
        { class: styles[boxType].content },
        children
      );

      return new Element(
        "div",
        {
          class: styles[boxType].box,
        },
        [pictogramElement, contentWrapper]
      );
    },
    "project-data": ({ node }) => {
      const projectDataNode = node as ProjectDataElement;

      const child = new Element("span", {}, [
        new Text(getValueByPath(project, projectDataNode.key) || ""),
      ]);

      return new Element("span", { class: "data-placeholder" }, [child]);
    },
  },
});

function getValueByPath(obj: any, path: string): any {
  return path.split(".").reduce((acc, key) => acc && acc[key], obj);
}
