import {
  RenderElementProps,
  TableCellElement,
  TableElement,
  TableRowElement,
  CustomElement,
} from "components/ManualBuilder/types";
import { ElementRenderer } from "../elementMap";
import React, { useState, useRef, useEffect } from "react";
import { Editor, Element as SlateElement, Transforms, Path } from "slate";
import { useSlate } from "slate-react";

interface ContextMenuProps {
  x: number;
  y: number;
  onClose: () => void;
  editor: Editor;
}

const TableContextMenu: React.FC<ContextMenuProps> = ({
  x,
  y,
  onClose,
  editor,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  const getCurrentTable = () => {
    for (const [node, path] of Editor.nodes(editor, {
      match: (n): n is TableElement =>
        SlateElement.isElement(n) && n.type === "table",
    })) {
      return [node, path] as [TableElement, Path];
    }
    return undefined;
  };

  const insertRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const rowCount = table.children.length;
      const columnCount = table.children[0].children.length;

      const newRow: TableRowElement = {
        type: "table-row",
        children: Array(columnCount)
          .fill(0)
          .map(() => ({
            type: "table-cell",
            children: [{ text: "" }],
          })),
      };

      Transforms.insertNodes(editor, newRow, {
        at: [...tablePath, rowCount],
      });
    }
    onClose();
  };

  const removeRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table] = tableEntry;
      if (table.children.length > 1) {
        const [, currentPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-row",
          }) || [];

        if (currentPath) {
          Transforms.removeNodes(editor, { at: currentPath });
        }
      }
    }
    onClose();
  };

  const insertColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      table.children.forEach((row: TableRowElement, rowIndex: number) => {
        const isHeader = rowIndex === 0;
        const newCell: TableCellElement = {
          type: "table-cell",
          isHeader,
          children: [{ text: "" }],
        };

        Transforms.insertNodes(editor, newCell, {
          at: [...tablePath, rowIndex, row.children.length],
        });
      });
    }
    onClose();
  };

  const removeColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const firstRow = table.children[0];
      if (firstRow.children.length > 1) {
        const [, cellPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-cell",
          }) || [];

        if (cellPath) {
          const columnIndex = cellPath[cellPath.length - 1];
          table.children.forEach((_: TableRowElement, rowIndex: number) => {
            Transforms.removeNodes(editor, {
              at: [...tablePath, rowIndex, columnIndex],
            });
          });
        }
      }
    }
    onClose();
  };

  return (
    <div
      ref={menuRef}
      style={{
        position: "fixed",
        top: y,
        left: x,
        zIndex: 1000,
      }}
      className="bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 w-48"
    >
      <div className="py-1" role="menu">
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={insertRow}
        >
          Add Row
        </button>
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={removeRow}
        >
          Remove Row
        </button>
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={insertColumn}
        >
          Add Column
        </button>
        <button
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          onClick={removeColumn}
        >
          Remove Column
        </button>
      </div>
    </div>
  );
};

export const tableElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) => {
    const [contextMenu, setContextMenu] = useState<{
      x: number;
      y: number;
    } | null>(null);
    const editor = useSlate();

    const handleContextMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu({ x: event.clientX, y: event.clientY });
    };

    return (
      <div className="my-4 overflow-x-auto">
        <table
          {...attributes}
          className="w-full border-collapse border border-gray-300"
          onContextMenu={handleContextMenu}
        >
          <tbody className="bg-white">{children}</tbody>
        </table>
        {contextMenu && (
          <TableContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={() => setContextMenu(null)}
            editor={editor}
          />
        )}
      </div>
    );
  };

export const tableRowElement: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <tr {...attributes} className="border-b border-gray-300">
        {children}
      </tr>
    );

export const tableCellElement: ElementRenderer =
  () =>
  ({ attributes, children, element }: RenderElementProps) => {
    const cellElement = element as TableCellElement;
    const cellStyle = {
      position: "relative",
      minWidth: "120px",
    } as const;

    const overlayStyle = {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: "none",
      backgroundColor: "transparent",
      border: "2px solid transparent",
    } as const;

    return cellElement.isHeader ? (
      <th
        {...attributes}
        className="border border-gray-300 bg-gray-100 p-2 text-left font-semibold"
        style={cellStyle}
      >
        <div contentEditable={false} style={overlayStyle} />
        {children}
      </th>
    ) : (
      <td
        {...attributes}
        className="border border-gray-300 p-2"
        style={cellStyle}
      >
        <div contentEditable={false} style={overlayStyle} />
        {children}
      </td>
    );
  };
