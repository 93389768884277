import { Descendant } from "slate";
import { CustomElement } from "./types";

interface H4Section {
  id: string;
  title: string;
}

interface H3Section {
  id: string;
  title: string;
  subsections: H4Section[];
}

interface SubChapter {
  id: string;
  title: string;
  h3sections: H3Section[];
}

interface Chapter {
  id: string;
  title: string;
  subChapters: SubChapter[];
  startIndex: number;
  endIndex: number;
}

type ChapterData = {
  id: string;
  title: string;
  subChapters: SubChapter[];
  startIndex: number;
  endIndex: number;
};

export const isHeading = (node: Descendant, level: 1 | 2 | 3 | 4): boolean => {
  if (!("type" in node)) return false;
  const expectedType = `h${level}`;
  return (
    node.type === expectedType &&
    Array.isArray(node.children) &&
    node.children.length > 0 &&
    "text" in node.children[0]
  );
};

export const generateChaptersFromContent = (
  content: Descendant[]
): Chapter[] => {
  const chapters: Chapter[] = [];

  let currentChapterData: ChapterData | null = null;
  let currentSubChapter: SubChapter | null = null;
  let currentH3Section: H3Section | null = null;

  content.forEach((node, index) => {
    if (isHeading(node, 1)) {
      // If we find an H1, end the previous chapter and start a new one
      if (currentChapterData) {
        const completedChapter: Chapter = {
          ...currentChapterData,
          endIndex: index - 1,
        };
        chapters.push(completedChapter);
      }
      currentChapterData = {
        id: `${chapters.length + 1}`,
        title: (node as any).children[0].text,
        subChapters: [],
        startIndex: index,
        endIndex: content.length - 1, // Will be updated when next H1 is found or at the end
      };
      currentSubChapter = null;
      currentH3Section = null;
    } else if (isHeading(node, 2) && currentChapterData) {
      // If we find an H2 and have a current chapter, add it as a subchapter
      currentSubChapter = {
        id: `${currentChapterData.id}.${
          currentChapterData.subChapters.length + 1
        }`,
        title: (node as any).children[0].text,
        h3sections: [],
      };
      currentChapterData.subChapters.push(currentSubChapter);
      currentH3Section = null;
    } else if (isHeading(node, 3) && currentChapterData && currentSubChapter) {
      // If we find an H3 and have a current subchapter, add it as an H3 section
      currentH3Section = {
        id: `${currentSubChapter.id}.${
          currentSubChapter.h3sections.length + 1
        }`,
        title: (node as any).children[0].text,
        subsections: [],
      };
      currentSubChapter.h3sections.push(currentH3Section);
    } else if (
      isHeading(node, 4) &&
      currentChapterData &&
      currentSubChapter &&
      currentH3Section
    ) {
      // If we find an H4 and have a current H3 section, add it as an H4 section
      const h4Section: H4Section = {
        id: `${currentH3Section.id}.${currentH3Section.subsections.length + 1}`,
        title: (node as any).children[0].text,
      };
      currentH3Section.subsections.push(h4Section);
    }
  });

  // Don't forget to add the last chapter
  if (currentChapterData) {
    const finalChapter: Chapter = {
      ...(currentChapterData as any),
      endIndex: content.length - 1,
    };
    chapters.push(finalChapter);
  }

  return chapters;
};

export const getChapterContent = (
  fullContent: Descendant[],
  chapter: Chapter
): Descendant[] => {
  return fullContent.slice(chapter.startIndex, chapter.endIndex + 1);
};

export const createNewChapterNode = (): CustomElement[] => {
  const newChapterNode: CustomElement = {
    type: "h1",
    children: [{ text: "Neues Kapitel" }],
  };
  const paragraphNode: CustomElement = {
    type: "paragraph",
    children: [{ text: "" }],
  };
  return [newChapterNode, paragraphNode];
};
