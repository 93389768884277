import {
  RenderElementProps,
  ImageElement,
} from "components/ManualBuilder/types";
import { ElementRenderer } from "../elementMap";

export const imageElement: ElementRenderer =
  () =>
  ({ attributes, children, element }: RenderElementProps) => {
    const imageElement = element as ImageElement;
    return (
      <div {...attributes} contentEditable={false} className="my-4">
        <div contentEditable={false}>
          <img
            src={imageElement.url}
            alt=""
            className="max-w-full h-auto rounded"
          />
        </div>
        {children}
      </div>
    );
  };
