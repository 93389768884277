import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Project } from "types";
import { Modal } from "components/Modal/Modal";
import { useProjects } from "hooks/useProjects";
import TextInput from "components/inputs/TextInput";
import RadioInput from "components/inputs/RadioInput";
import { ImageType } from "components/Documents/types";
import SelectInput from "components/inputs/SelectInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import {
  UploadableImage,
  useCreateProject,
} from "pages/Project/hooks/useCreateProject";
import i18n from "i18n";
import { Feature, useFeatureFlags } from "context/FeatureFlag";

interface CreateProjectOnDashboardProps {
  button: React.FunctionComponent<React.ButtonHTMLAttributes<any>>;
}

export const CreateProjectOnDashboard: React.FunctionComponent<
  CreateProjectOnDashboardProps
> = (props) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <div className="mt-4">
        <props.button onClick={() => setModalVisible(true)} />
      </div>

      <>
        <Modal
          onClose={() => setModalVisible(false)}
          show={modalVisible}
          width="1/2"
          content={<CreateProjectForm />}
        />
      </>
    </>
  );
};

async function createFileFromUrl(url: string, documentName: string) {
  const response = await fetch(url);
  if (!response.ok) throw new Error("Network response was not ok");

  const blob = await response.blob();

  const file = new File([blob], documentName, { type: blob.type });

  return file;
}

enum CreateOption {
  // template = "template",
  scratch = "scratch",
  copy = "copy",
}

interface CreateRiskFormProps {}

const CreateProjectForm = (props: CreateRiskFormProps) => {
  const { t } = useTranslation();
  const { projects } = useProjects();
  const currentLanguage = i18n.language.split("-")[0];
  const { createProject, isLoading } = useCreateProject();
  const [payload, setPayload] = React.useState({
    name: "",
    language: currentLanguage,
  });

  const [selectedOption, setSelectedOption] = useState<CreateOption>(
    CreateOption.scratch
  );

  const [selectedCopy, setSelectedCopy] = useState<Project>();
  const [languages, setLanguages] = useState(["de", "en"]);

  const { isFeatureEnabled } = useFeatureFlags();

  React.useEffect(() => {
    if (isFeatureEnabled(Feature.SecondBatchLanguages)) {
      setLanguages([
        "de",
        "en",
        "fr",
        "it",
        "es",
        "sv",
        "pl",
        "hu",
        "sk",
        "sl",
      ]);
    }
  }, [isFeatureEnabled]);

  const handleCreateProject = async () => {
    if (selectedOption === CreateOption.scratch) {
      createProject({
        project: {
          languages: [{ code: payload.language }],
          // @ts-ignore
          projectdata: {
            product_name: payload.name,
          },
        },
      });

      return;
    }

    const hasUploads = selectedCopy?.documents?.length;
    const uploads = {} as Record<string, UploadableImage>;

    if (hasUploads) {
      const uploadlinks =
        selectedCopy?.documents
          ?.filter(
            (doc) =>
              doc.type === ImageType.manufacturerLogo ||
              doc.type === ImageType.mainProductImage
          )
          ?.map(async (doc) => {
            const upload: UploadableImage = {
              image: await createFileFromUrl(
                doc.location,
                doc.name.split("/upload/")[1]
              ),
              key: doc.type,
              touched: true,
            };

            return upload;
          }) || [];

      await Promise.all(uploadlinks).then((resolvedUploads) => {
        resolvedUploads.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, uploads);
      });
    }

    createProject({
      uploads,
      project: {
        ...selectedCopy,
        languages: [{ code: payload.language }],
        documents: [],
        // @ts-ignore
        projectdata: {
          ...selectedCopy?.projectdata,
          crn: "",
          resource_id: "",
          version: 0,
          product_name: payload.name,
          completed_research: false,
        },
      },
    });
  };

  return (
    <div>
      <span className="text-xl">{t("dashboard.create.headline")}</span>
      <div className="pt-4">
        <TextInput
          data-test="create-project-name"
          id="product"
          required
          uncontrolled
          label={t("dashboard.create.product_name")}
          // @ts-ignore
          onChange={(e) => {
            setPayload((p) => ({ ...p, name: e.target.value }));
          }}
          placeholder={t("dashboard.create.placeholder")}
          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evt.key === "Enter") {
              payload.name && handleCreateProject();
            }
          }}
        />

        {isFeatureEnabled(Feature.MultiLanguage) && (
          <div className="py-2">
            <SelectInput
              required
              id="language"
              name="language"
              defaultValue={currentLanguage}
              label={t("dashboard.create.language")}
              onBlur={(option) =>
                setPayload((p) => ({ ...p, language: option.target.value }))
              }
              options={languages.map((lang) => ({
                value: lang,
                label: t(`languages.${lang}`),
              }))}
            />
          </div>
        )}

        <RadioInput
          data-test="create-project-template"
          islast
          required
          id="useTemplate"
          name="useTemplate"
          label={t("dashboard.create.create_choice")}
          defaultValue={selectedOption}
          onBlur={(option) =>
            setSelectedOption(option.target.value as CreateOption.scratch)
          }
          options={[
            {
              label: t(`dashboard.create.options.${CreateOption.scratch}`),
              value: CreateOption.scratch,
            },
            {
              label: t(`dashboard.create.options.${CreateOption.copy}`),
              value: CreateOption.copy,
            },
          ]}
        />

        {selectedOption === CreateOption.copy && (
          <SelectInput
            required
            id="template"
            name="template"
            label={t("dashboard.create.copy")}
            onBlur={(option) =>
              setSelectedCopy(
                projects!.find(
                  (t) => t.projectdata.crn === option.target.value
                )!
              )
            }
            options={
              projects
                ?.sort((a, b) =>
                  a.projectdata.product_name.localeCompare(
                    b.projectdata.product_name
                  )
                )
                .map((template) => ({
                  value: template.projectdata.crn,
                  label: `${template.projectdata.product_name!} | ${template
                    .manufacturer.company!}`,
                })) || []
            }
          />
        )}
      </div>

      <div className="pt-4">
        <PrimaryButton
          testId="create-project"
          float="right"
          disabled={
            !payload.name ||
            (selectedOption === CreateOption.copy && !selectedCopy) ||
            isLoading
          }
          onClick={handleCreateProject}
        >
          {isLoading
            ? t("dashboard.create.cta_loading")
            : t("dashboard.create.cta")}
        </PrimaryButton>
      </div>
    </div>
  );
};
