import React from "react";
import { Descendant } from "slate";
import { Slate, Editable } from "slate-react";
import { ManualEditorProps } from "components/ManualBuilder/types";
import { Toolbar } from "components/ManualBuilder/components/toolbar";
import { useEditorConfig } from "./hooks/useEditorConfig";
import { useKeyboardHandlers } from "./hooks/useKeyboardHandlers";
import { useEditorRenderers } from "./hooks/useEditorRenderers";

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "Start typing here..." }],
  },
];

export const ManualEditor = (props: ManualEditorProps) => {
  const editor = useEditorConfig();
  const onKeyDown = useKeyboardHandlers(editor);
  const { renderElement, renderLeaf } = useEditorRenderers();

  if (!props.defaultValue?.length) {
    return <>Wähle zunächst ein Kapitel aus der Liste</>;
  }

  return (
    <div>
      <div
        className="bg-white rounded-md shadow relative overflow-hidden"
        style={{ height: "75vh" }}
      >
        <Slate
          editor={editor}
          onChange={(content) => props.onBlur?.(content)}
          initialValue={props.defaultValue || initialValue}
        >
          <div className="sticky top-0 z-10 bg-white border-b">
            <Toolbar />
          </div>
          <div className="p-4 overflow-y-auto h-[calc(100%-48px)]">
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Start typing..."
              className="min-h-[200px] focus:outline-none"
              onKeyDown={onKeyDown}
            />
          </div>
        </Slate>
      </div>
    </div>
  );
};
