import React from "react";
import { useSlateStatic } from "slate-react";

import { elementMap } from "./elementMap";
import { CustomText, RenderElementProps } from "components/ManualBuilder/types";

export const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: React.HTMLAttributes<HTMLSpanElement>;
  children: React.ReactNode;
  leaf: CustomText;
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

export const Element = (props: RenderElementProps) => {
  const { element, attributes, children } = props;

  const editor = useSlateStatic();
  const getElementRenderer = elementMap[element.type];

  if (getElementRenderer) {
    const renderer = getElementRenderer(editor);

    return renderer(props);
  }

  return <p {...attributes}>{children}</p>;
};
