import React from "react";
import { Editor, Element as SlateElement, Transforms, Path } from "slate";
import { useSlate } from "slate-react";
import { CustomElement, HeadingLevel } from "../../types";

interface HeadingButtonProps {
  level: HeadingLevel;
  icon: string;
}

export const HeadingButton: React.FC<HeadingButtonProps> = ({
  level,
  icon,
}) => {
  const editor = useSlate();

  const isActiveHeading = () => {
    const matches = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) && "type" in n && n.type === `h${level}`,
      })
    );
    return matches.length > 0;
  };

  const isFirstBlock = () => {
    if (!editor.selection) return false;
    const [, path] = Editor.node(editor, editor.selection);
    return Path.equals(path, [0]);
  };

  // Disable H1 button if not the first block
  const isDisabled = level === 1 && !isFirstBlock();

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={`p-2 ${
        isActiveHeading() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded ${
        isDisabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        if (isDisabled) return;

        const isActive = isActiveHeading();

        Transforms.setNodes(
          editor,
          {
            type: isActive ? "paragraph" : `h${level}`,
          } as Partial<CustomElement>,
          {
            match: (n) =>
              SlateElement.isElement(n) && Editor.isBlock(editor, n),
          }
        );
      }}
      title={
        isDisabled
          ? "H1 can only be used for the first line"
          : `Heading ${level}`
      }
    >
      {icon}
    </button>
  );
};
