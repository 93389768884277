import { Editor, Element as SlateElement } from "slate";
import {
  CustomEditor,
  MessageBoxType,
  MessageBoxElement,
} from "components/ManualBuilder/types";

export const getParentMessageBox = (
  editor: CustomEditor,
  path: number[]
): MessageBoxType => {
  try {
    const [node] = Editor.parent(editor, path);
    if (SlateElement.isElement(node) && node.type === "message-box") {
      return (node as MessageBoxElement).boxType;
    }
    return "warning"; // fallback
  } catch {
    return "warning"; // fallback
  }
};

export const getPictogramUrl = (boxType: MessageBoxType): string => {
  const picto = {
    warning: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/W001`,
    danger: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/W001`,
    info: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/M001`,
  };
  return picto[boxType];
};

export const getMessageBoxStyle = (
  boxType: MessageBoxType
): Record<string, string> => {
  const styles = {
    warning: {
      box: "bg-yellow-50 border border-yellow-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "px-4 pt-2 font-semibold text-yellow-800",
      content: "p-4",
    },
    info: {
      box: "bg-blue-50 border border-blue-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "px-4 pt-2 font-semibold text-blue-800",
      content: "p-4",
    },
    danger: {
      box: "bg-red-50 border border-red-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "px-4 pt-2 font-semibold text-red-800",
      content: "p-4",
    },
  };
  return styles[boxType];
};
