import { useMemo } from "react";
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms,
  Path,
  Node,
  Text,
} from "slate";
import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import { CustomElement, CustomText } from "components/ManualBuilder/types";

const withProjectData = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "project-data" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "project-data" ? true : isVoid(element);
  };

  return editor;
};

const withImages = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

const withTables = (editor: Editor) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (selection && SlateElement.isElement(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        return;
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    const { selection } = editor;

    if (selection && SlateElement.isElement(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        return;
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table",
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

const withLists = (editor: Editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && selection.anchor.offset === 0) {
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "li",
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (
          selection.anchor.offset === 0 &&
          selection.anchor.path[0] === start.path[0]
        ) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              (n.type === "ul" || n.type === "ol"),
            split: true,
          });
          Transforms.setNodes(editor, { type: "paragraph" });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

const withHeadings = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (!Editor.isEditor(node)) return normalizeNode([node, path]);

      const children = Array.from(Node.children(editor, path));

      // Check if first element is not an H1
      if (children.length > 0) {
        const [firstChild] = children[0];
        if (SlateElement.isElement(firstChild) && firstChild.type !== "h1") {
          Transforms.setNodes(
            editor,
            { type: "h1" } as Partial<CustomElement>,
            { at: [0] }
          );
          return;
        }
      }

      // Check for any H1s after the first element
      for (let i = 1; i < children.length; i++) {
        const [child] = children[i];
        if (SlateElement.isElement(child) && child.type === "h1") {
          // Convert any subsequent H1s to H2s
          Transforms.setNodes(
            editor,
            { type: "h2" } as Partial<CustomElement>,
            { at: [i] }
          );
          return;
        }
      }
    }

    // Fall back to the original normalizeNode
    normalizeNode([node, path]);
  };

  return editor;
};

const isEmptyParagraph = (node: Node): boolean => {
  return (
    SlateElement.isElement(node) &&
    node.type === "paragraph" &&
    node.children.length === 1 &&
    Text.isText(node.children[0]) &&
    node.children[0].text === ""
  );
};

const withEmptyLastLine = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (!Editor.isEditor(node)) return normalizeNode([node, path]);

      const children = Array.from(Node.children(editor, path));

      if (children.length === 0) {
        // If document is empty, insert an empty paragraph
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text: "" }] } as CustomElement,
          { at: [0] }
        );
        return;
      }

      const lastChild = children[children.length - 1][0];

      // Check if we need to add an empty paragraph
      const needsEmptyParagraph =
        !isEmptyParagraph(lastChild) ||
        (SlateElement.isElement(lastChild) &&
          (lastChild.type === "message-box" ||
            lastChild.type === "table" ||
            lastChild.type === "content" || // For message box content
            lastChild.type === "table-row")); // For table rows

      if (needsEmptyParagraph) {
        // Insert an empty paragraph at the end
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text: "" }] } as CustomElement,
          { at: [children.length] }
        );
        return;
      }

      // If there's only one empty paragraph, make sure it's not inside a message box or table
      if (children.length === 1 && isEmptyParagraph(lastChild)) {
        const [parent] = Editor.parent(editor, path);
        if (
          SlateElement.isElement(parent) &&
          (parent.type === "message-box" || parent.type === "table")
        ) {
          Transforms.insertNodes(
            editor,
            { type: "paragraph", children: [{ text: "" }] } as CustomElement,
            { at: [1] }
          );
          return;
        }
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};

export const useEditorConfig = () => {
  const editor = useMemo(
    () =>
      withProjectData(
        withImages(
          withLists(
            withTables(
              withHeadings(
                withEmptyLastLine(withHistory(withReact(createEditor())))
              )
            )
          )
        )
      ),
    []
  );

  return editor;
};
