import { useTranslation } from "react-i18next";
import { useSlate, ReactEditor } from "slate-react";
import React, { useState, useRef, useEffect } from "react";
import { Transforms, Path } from "slate";

import { ElementRenderer } from "../elementMap";
import { ProjectDataElement } from "components/ManualBuilder/types";
import { RenderElementProps } from "components/ManualBuilder/types";
import { PROJECT_DATA_PLACEHOLDERS } from "./utils";

export const projectDataElement: ElementRenderer =
  () =>
  ({ attributes, children, element }: RenderElementProps) => {
    const { t } = useTranslation();
    const editor = useSlate();
    const projectDataElement = element as ProjectDataElement;
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          menuRef.current &&
          event.target instanceof HTMLElement &&
          !menuRef.current.contains(event.target)
        ) {
          setShowMenu(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
      <span ref={menuRef} className="relative">
        <span
          {...attributes}
          contentEditable={false}
          className="inline text-sm bg-blue-100 border border-blue-300 text-blue-800 rounded px-1 mx-0.5 whitespace-nowrap align-baseline cursor-pointer hover:bg-blue-200"
          onMouseDown={(e) => {
            e.preventDefault();
            setShowMenu(!showMenu);
          }}
        >
          {t(`manual.placeholder.${projectDataElement.key}`)}
          <span className="sr-only">{children}</span>
        </span>
        {showMenu && (
          <div className="absolute z-50 bg-white border rounded-lg shadow-lg py-1 min-w-[250px] left-0 top-full mt-1">
            {PROJECT_DATA_PLACEHOLDERS.map((group, groupIndex) => (
              <div key={groupIndex}>
                <div className="px-4 py-2 text-sm text-gray-700 font-bold">
                  {t(`manual.placeholder.${group.label}`)}
                </div>
                {group.options.map((placeholder) => (
                  <button
                    key={placeholder.key}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      const path = ReactEditor.findPath(editor, element);
                      Transforms.setNodes(
                        editor,
                        { key: placeholder.key },
                        { at: path }
                      );
                      setShowMenu(false);
                    }}
                  >
                    {t(`manual.placeholder.${placeholder.key}`)}
                  </button>
                ))}
                {groupIndex < PROJECT_DATA_PLACEHOLDERS.length - 1 && (
                  <div className="border-b my-1"></div>
                )}
              </div>
            ))}
          </div>
        )}
      </span>
    );
  };
