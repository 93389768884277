import React from "react";
import { useSlate } from "slate-react";
import { FormatMark } from "../../types";
import { isMarkActive, toggleMark } from "./utils";

interface MarkButtonProps {
  format: FormatMark;
  icon: string;
}

export const MarkButton: React.FC<MarkButtonProps> = ({ format, icon }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <button
      type="button"
      className={`p-2 ${
        isActive ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </button>
  );
};
