import { ProjectData } from "types";
import { Transforms, Element, Range, Node, Path } from "slate";

import {
  CustomEditor,
  ProjectDataElement,
} from "components/ManualBuilder/types";

export interface ProjectDataPlaceholder {
  key: string;
}

export interface PlaceholderGroup {
  label: string;
  options: ProjectDataPlaceholder[];
}

export const PROJECT_DATA_PLACEHOLDERS: PlaceholderGroup[] = [
  {
    label: "projectdata.label",
    options: [
      {
        key: "projectdata.description",
      },
      {
        key: "projectdata.product_name",
      },
    ],
  },
  {
    label: "manufacturer.label",
    options: [
      {
        key: "manufacturer.company",
      },
    ],
  },
  {
    label: "boundary.label",
    options: [
      {
        key: "boundary.expected_misuse",
      },
    ],
  },
];

export const insertProjectDataPlaceholder = (
  editor: CustomEditor,
  key: string
): void => {
  if (!editor.selection) return;

  const projectData: ProjectDataElement = {
    type: "project-data",
    key,
    children: [{ text: "" }],
  };

  // If there's a selection range, delete it first
  if (!Range.isCollapsed(editor.selection)) {
    Transforms.delete(editor);
  }

  // Insert at the current selection point
  Transforms.insertNodes(editor, projectData, {
    at: editor.selection,
    select: true,
  });

  // Move cursor after the placeholder
  Transforms.move(editor);
};

export const isProjectDataElement = (
  element: Element
): element is ProjectDataElement => {
  return element.type === "project-data";
};
