import React, { useState, useRef, useEffect } from "react";
import { useSlate } from "slate-react";
import { useTranslation } from "react-i18next";

import {
  PROJECT_DATA_PLACEHOLDERS,
  insertProjectDataPlaceholder,
} from "../elements/projectData/utils";
import { VariableIcon } from "@heroicons/react/outline";

export const ProjectDataButton = () => {
  const editor = useSlate();
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        event.target instanceof HTMLElement &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        className="p-2 rounded hover:bg-gray-100 flex items-center"
        title="Insert Project Data"
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          setShowMenu(!showMenu);
        }}
      >
        <span className="text-sm font-medium">
          <VariableIcon className="h-6 w-6 text-gray-600" />
        </span>
      </button>
      {showMenu && (
        <div className="absolute z-50 bg-white border rounded-lg shadow-lg py-1 min-w-[250px] left-0 top-full mt-1">
          {PROJECT_DATA_PLACEHOLDERS.map((group, groupIndex) => (
            <div key={groupIndex}>
              <div className="px-4 py-2 text-sm text-gray-700 font-bold">
                {t(`manual.placeholder.${group.label}`)}
              </div>
              {group.options.map((placeholder) => (
                <button
                  key={placeholder.key}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertProjectDataPlaceholder(editor, placeholder.key);
                    setShowMenu(false);
                  }}
                >
                  {t(`manual.placeholder.${placeholder.key}`)}
                </button>
              ))}
              {groupIndex < PROJECT_DATA_PLACEHOLDERS.length - 1 && (
                <div className="border-b my-1"></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
