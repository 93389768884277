import React, { ReactNode } from "react";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";
import { CustomEditor, CustomElement, MessageBoxType } from "../../types";

interface MessageBoxButtonProps {
  format: MessageBoxType;
}

export const MessageBoxButton: React.FC<MessageBoxButtonProps> = ({
  format,
}) => {
  const editor = useSlate();

  const isActiveMessageBox = () => {
    const matches = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          "type" in n &&
          n.type === "message-box" &&
          "boxType" in n &&
          n.boxType === format,
      })
    );
    return matches.length > 0;
  };

  const icons: Record<MessageBoxType, ReactNode> = {
    warning: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-yellow-600"
      >
        <path
          fillRule="evenodd"
          d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    info: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-blue-600"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    danger: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-red-600"
      >
        <path
          fillRule="evenodd"
          d="M11.484 2.17a.75.75 0 0 1 1.032 0 11.209 11.209 0 0 0 7.877 3.08.75.75 0 0 1 .722.515 12.74 12.74 0 0 1 .635 3.985c0 5.942-4.064 10.933-9.563 12.348a.749.749 0 0 1-.374 0C6.314 20.683 2.25 15.692 2.25 9.75c0-1.39.223-2.73.635-3.985a.75.75 0 0 1 .722-.516l.143.001c2.996 0 5.718-1.17 7.734-3.08ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 15a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75H12Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  };

  return (
    <button
      type="button"
      className={`p-2 ${
        isActiveMessageBox() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        const isActive = isActiveMessageBox();

        if (isActive) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              SlateElement.isElement(n) &&
              "type" in n &&
              n.type === "message-box",
            split: true,
          });
        } else {
          const messageBox = {
            type: "message-box",
            boxType: format,
            children: [
              {
                type: "title",
                children: [{ text: "Title" }],
              },
              {
                type: "content",
                children: [{ text: "Content" }],
              },
            ],
          } as CustomElement;

          Transforms.insertNodes(editor, messageBox);
        }
      }}
    >
      {icons[format]}
    </button>
  );
};
