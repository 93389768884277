import { BaseEditor, Descendant } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

export type HeadingLevel = 1 | 2 | 3 | 4;
export type ListType = "bulleted" | "numbered";
export type MessageBoxType = "warning" | "info" | "danger";
export type FormatMark = "bold" | "italic" | "underline";

export const HOTKEYS: { [key: string]: FormatMark } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

export interface CustomText {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export interface ProjectDataElement {
  type: "project-data";
  key: string;
  children: CustomText[];
}

// Define a type for elements that can contain project data
type ElementWithProjectData = CustomText | ProjectDataElement;

export interface HeadingElement {
  type: "h1" | "h2" | "h3" | "h4";
  children: ElementWithProjectData[];
}

export interface ParagraphElement {
  type: "paragraph";
  children: ElementWithProjectData[];
}

export interface ImageElement {
  type: "image";
  url: string;
  children: ElementWithProjectData[];
}

export interface ListItemElement {
  type: "li";
  children: ElementWithProjectData[];
}

export interface UnorderedListElement {
  type: "ul";
  children: ListItemElement[];
}

export interface OrderedListElement {
  type: "ol";
  children: ListItemElement[];
}

export interface TableCellElement {
  type: "table-cell";
  isHeader?: boolean;
  children: ElementWithProjectData[];
}

export interface TableRowElement {
  type: "table-row";
  children: TableCellElement[];
}

export interface TableElement {
  type: "table";
  children: TableRowElement[];
}

export interface TitleElement {
  type: "title";
  children: ElementWithProjectData[];
}

export interface ContentElement {
  type: "content";
  children: ElementWithProjectData[];
}

export interface MessageBoxElement {
  type: "message-box";
  boxType: MessageBoxType;
  children: (TitleElement | ContentElement)[];
}

export type CustomElement =
  | HeadingElement
  | ParagraphElement
  | ImageElement
  | UnorderedListElement
  | OrderedListElement
  | ListItemElement
  | TableElement
  | TableRowElement
  | TableCellElement
  | MessageBoxElement
  | TitleElement
  | ContentElement
  | ProjectDataElement;

export interface RenderElementProps {
  attributes: any;
  children: React.ReactNode;
  element: CustomElement;
}

export interface ManualEditorProps {
  label?: string;
  defaultValue: Descendant[];
  onBlur?: (value: Descendant[]) => void;
}

export interface H4Section {
  id: string;
  title: string;
}

export interface H3Section {
  id: string;
  title: string;
  subsections: H4Section[];
}

export interface SubChapter {
  id: string;
  title: string;
  h3sections: H3Section[];
}

export interface Chapter {
  id: string;
  title: string;
  subChapters: SubChapter[];
  startIndex: number;
  endIndex: number;
}

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
