import React from "react";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";
import { CustomElement, ListType } from "../../types";

interface ListButtonProps {
  format: ListType;
  icon: "bullet" | "list";
}

export const ListButton: React.FC<ListButtonProps> = ({ format, icon }) => {
  const editor = useSlate();

  const isActiveList = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) =>
        SlateElement.isElement(n) &&
        ((format === "bulleted" && n.type === "ul") ||
          (format === "numbered" && n.type === "ol")),
    });
    return !!match;
  };

  const toggleList = () => {
    const isActive = isActiveList();
    const listType = format === "bulleted" ? "ul" : "ol";

    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          ((format === "bulleted" && n.type === "ul") ||
            (format === "numbered" && n.type === "ol")),
        split: true,
      });
      Transforms.setNodes(
        editor,
        { type: "paragraph" } as Partial<CustomElement>,
        {
          match: (n) => SlateElement.isElement(n) && n.type === "li",
        }
      );
    } else {
      // Unwrap any existing lists first
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) && (n.type === "ul" || n.type === "ol"),
        split: true,
      });

      // Convert current nodes to list items
      Transforms.setNodes(editor, { type: "li" } as Partial<CustomElement>, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      // Wrap in list
      Transforms.wrapNodes(editor, {
        type: listType,
        children: [],
      } as CustomElement);
    }
  };

  return (
    <button
      type="button"
      className={`p-2 ${
        isActiveList() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleList();
      }}
    >
      {icon === "bullet" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M7.491 5.992a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM7.49 11.995a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM7.491 17.994a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.24 3.745a.75.75 0 0 1 .75-.75h1.125a.75.75 0 0 1 .75.75v3h.375a.75.75 0 0 1 0 1.5H2.99a.75.75 0 0 1 0-1.5h.375v-2.25H2.99a.75.75 0 0 1-.75-.75ZM2.79 10.602a.75.75 0 0 1 0-1.06 1.875 1.875 0 1 1 2.652 2.651l-.55.55h.35a.75.75 0 0 1 0 1.5h-2.16a.75.75 0 0 1-.53-1.281l1.83-1.83a.375.375 0 0 0-.53-.53.75.75 0 0 1-1.062 0ZM2.24 15.745a.75.75 0 0 1 .75-.75h1.125a1.875 1.875 0 0 1 1.501 2.999 1.875 1.875 0 0 1-1.501 3H2.99a.75.75 0 0 1 0-1.501h1.125a.375.375 0 0 0 .036-.748H3.74a.75.75 0 0 1-.75-.75v-.002a.75.75 0 0 1 .75-.75h.411a.375.375 0 0 0-.036-.748H2.99a.75.75 0 0 1-.75-.75Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
};
