import { AxiosInstance } from "axios";
import { Category } from "components/common/ProductCategorySelector";
import { ControlContext } from "context/Controls";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Mapping, Project } from "types";
import { useClient } from "utils/client";
import { useTranslation } from "react-i18next";

const fetchProjects = async (client: AxiosInstance) => {
  const { data } = await client.get("/projects");
  return data;
};

export const useProjects = () => {
  const client = useClient();
  const { displayNotification } = useContext(ControlContext);

  const { data: projects, isLoading } = useQuery<Project[]>(
    ["projects"],
    () => fetchProjects(client),
    {
      onError: (e) => {
        displayNotification!(
          "Projekte konnten nicht geladen werden. Seite bitte manuell neuladen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
    }
  );

  return { projects: projects || [], isLoading };
};

export const useOneProject = (projectCrn: string): Project | undefined => {
  const projectPayload = useProjects();

  const project = projectPayload.projects?.length
    ? projectPayload.projects.find((p) => p.projectdata.crn === projectCrn)
    : undefined;

  return project;
};

export const useDeleteProject = () => {
  const axios = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    (projectId: string) => axios.delete(`/projects/${projectId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["projects"]);

        displayNotification!("Projekt erfolgreich gelöscht", "", "success");
      },
    }
  );

  return { deleteProject: mutate };
};

export const useUpdateProject = (project: Project, successMessage?: string) => {
  const axios = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    (projectId: string) =>
      axios.put(`/projects/${projectId}`, { payload: project }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["projects", project.projectdata.id]);
        successMessage && displayNotification!(successMessage, "", "success");
      },
      onError: (e) => {
        displayNotification!(
          "Projekt konnte nicht vollständig aktualisiert werden. Eingaben überprüfen und erneut versuchen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
    }
  );

  return { updateProject: mutate };
};

export const useCategoryMapping = (): Mapping => {
  const { t } = useTranslation();

  return {
    "projectdata.category": [
      {
        translation: t("common.selector.product_category.none"),
        value: Category.none,
      },
      {
        translation: t("common.selector.product_category.machine"),
        value: Category.machine,
      },
      {
        translation: t(
          "common.selector.product_category.partially_completed_machinery"
        ),
        value: Category.partially_completed_machinery,
      },
      {
        translation: t(
          "common.selector.product_category.assembly_of_machinery"
        ),
        value: Category.assembly_of_machinery,
      },
      {
        translation: t("common.selector.product_category.safety_Component"),
        value: Category.safety_Component,
      },
      {
        translation: t("common.selector.product_category.lifting_accessory"),
        value: Category.lifting_accessory,
      },
      {
        translation: t("common.selector.product_category.chains_ropes_webbing"),
        value: Category.chains_ropes_webbing,
      },
      {
        translation: t(
          "common.selector.product_category.removable_mechanical_transmission_device"
        ),
        value: Category.removable_mechanical_transmission_device,
      },
      {
        translation: t(
          "common.selector.product_category.lifting_device_human_power"
        ),
        value: Category.lifting_device_human_power,
      },
      {
        translation: t(
          "common.selector.product_category.interchangeable_equipment"
        ),
        value: Category.interchangeable_equipment,
      },
      {
        translation: t("common.selector.product_category.annex_iv"),
        value: Category.annex_iv,
      },
      {
        translation: t("common.selector.product_category.exit"),
        value: Category.exit,
      },
      {
        translation: t("common.selector.product_category.lvd"),
        value: Category.lvd,
      },
    ],
  };
};

export const useUpdateProjectPayload = (
  projectCRN: string,
  successMessage?: string
) => {
  const axios = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    (payload: Project) => axios.put(`/projects/${projectCRN}`, { payload }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["projects", projectCRN]);
        successMessage && displayNotification!(successMessage, "", "success");
      },
      onError: (e) => {
        displayNotification!(
          "Projekt konnte nicht vollständig aktualisiert werden. Eingaben überprüfen und erneut versuchen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
    }
  );

  return { updateProject: mutate, isLoading };
};
