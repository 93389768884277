import { RenderElementProps } from "components/ManualBuilder/types";
import { ElementRenderer } from "../elementMap";

export const h1Element: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <h1 {...attributes} className="text-2xl font-bold mb-4">
        {children}
      </h1>
    );

export const h2Element: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <h2 {...attributes} className="text-xl font-bold mb-3">
        {children}
      </h2>
    );

export const h3Element: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <h3 {...attributes} className="text-lg font-bold mb-2">
        {children}
      </h3>
    );

export const h4Element: ElementRenderer =
  () =>
  ({ attributes, children }: RenderElementProps) =>
    (
      <h4 {...attributes} className="text-md font-bold mb-2">
        {children}
      </h4>
    );
