import React, { useState, useEffect } from "react";
import { BasePage } from "components/BasePage/BasePage";
import { Breadcrumb } from "components/common/Breadcrumbs";
import { Descendant } from "slate";
import { dummyContent } from "./dummycontent";
import { useParams } from "react-router";
import { useManual, useManualSave } from "./hooks";
import { Chapter, MessageBoxElement, SubChapter } from "./types";
import { ChapterList } from "./components/ChapterList";
import { ChapterEditor } from "./components/ChapterEditor";
import {
  generateChaptersFromContent,
  getChapterContent,
  createNewChapterNode,
  isHeading,
} from "./utils";
import { Element, Text } from "domhandler";
import { MessageBoxType } from "./types";

// ----------------------------------------------------
import { slateToHtml, slateToHtmlConfig } from "@slate-serializers/html";
import {
  slateToTemplate,
  slateToTemplateConfig,
} from "@slate-serializers/template";
import { getHtmlTransforms } from "./transforms/html";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";

// ----------------------------------------------------

interface ManualBuilderSearchParams {
  projectId: string;
}

const ManualBuilder = () => {
  const { projectId } = useParams<ManualBuilderSearchParams>();

  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);
  const [selectedSubChapter, setSelectedSubChapter] =
    useState<SubChapter | null>(null);
  const [fullContent, setFullContent] = useState<Descendant[]>([]);

  const { storeManual } = useManualSave(projectId);
  const { manual, isLoading } = useManual(projectId);
  const { project } = useProjectDetails(projectId);

  const breadcrumbItems: Breadcrumb[] = [
    { label: "projects", location: "/dashboard" },
    {
      label: project?.projectdata?.product_name || projectId,
      location: `/projects/${projectId}`,
    },
    { label: "Betriebsanleitung", location: `/projects/${projectId}/manual` },
  ];

  useEffect(() => {
    if (!isLoading) {
      setFullContent(manual || dummyContent);
    }
  }, [manual, isLoading]);

  useEffect(() => {
    // Generate initial chapters from content
    const initialChapters = generateChaptersFromContent(fullContent);
    setChapters(initialChapters);
    if (!selectedChapter) {
      setSelectedChapter(initialChapters[0]);
    }
  }, [fullContent]);

  const handleSave = (updatedContent: Descendant[]) => {
    if (selectedChapter) {
      // Create a new array with the updated content spliced in
      const newContent = [
        ...fullContent.slice(0, selectedChapter.startIndex),
        ...updatedContent,
        ...fullContent.slice(selectedChapter.endIndex + 1),
      ];

      // Update the full content
      setFullContent(newContent);

      console.log(newContent);
      console.log(slateToHtml(newContent, getHtmlTransforms(project)));

      // Regenerate chapters with new content to update indices
      const updatedChapters = generateChaptersFromContent(newContent);
      setChapters(updatedChapters);

      // Update selected chapter with new indices
      const newSelectedChapter = updatedChapters.find(
        (c) => c.id === selectedChapter.id
      );
      if (newSelectedChapter) {
        setSelectedChapter(newSelectedChapter);
      }
    }
  };

  const handleChapterClick = (chapter: Chapter) => {
    setSelectedChapter(chapter);
    setSelectedSubChapter(null);
  };

  const handleSubChapterClick = (chapter: Chapter, subChapter: SubChapter) => {
    setSelectedChapter(chapter);
    setSelectedSubChapter(subChapter);
  };

  const handleSaveButton = () => {
    // Generate chapters from the current content
    const generatedChapters = generateChaptersFromContent(fullContent);
    setChapters(generatedChapters);
    storeManual(fullContent);
  };

  const handleAddNewChapter = () => {
    const newNodes = createNewChapterNode();
    const newContent = [...fullContent, ...newNodes];
    setFullContent(newContent);

    const updatedChapters = generateChaptersFromContent(newContent);
    setChapters(updatedChapters);

    // Select the newly created chapter
    const newChapter = updatedChapters[updatedChapters.length - 1];
    setSelectedChapter(newChapter);
    setSelectedSubChapter(null);
  };

  const handleDeleteChapter = (chapter: Chapter, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent chapter selection when clicking delete

    // Create new content without the deleted chapter
    const newContent = [
      ...fullContent.slice(0, chapter.startIndex),
      ...fullContent.slice(chapter.endIndex + 1),
    ];

    // Update content and regenerate chapters
    setFullContent(newContent);
    const updatedChapters = generateChaptersFromContent(newContent);
    setChapters(updatedChapters);

    // Clear selection if the deleted chapter was selected
    if (selectedChapter?.id === chapter.id) {
      setSelectedChapter(null);
      setSelectedSubChapter(null);
    }
  };

  const handleDeleteSubChapter = (
    chapter: Chapter,
    subChapter: SubChapter,
    subChapterIndex: number,
    e: React.MouseEvent
  ) => {
    e.stopPropagation(); // Prevent subchapter selection when clicking delete

    // Find the H2 heading in the content
    let h2Index = -1;
    let nextH2Index = -1;
    let foundCount = 0;

    for (let i = chapter.startIndex; i <= chapter.endIndex; i++) {
      if (isHeading(fullContent[i], 2)) {
        foundCount++;
        if (foundCount === subChapterIndex + 1) {
          h2Index = i;
        } else if (foundCount === subChapterIndex + 2) {
          nextH2Index = i;
          break;
        }
      }
    }

    if (h2Index === -1) return;

    // If this is the last subchapter, remove until the end of the chapter or the next H1
    const endIndex = nextH2Index === -1 ? chapter.endIndex : nextH2Index - 1;

    // Create new content without the deleted subchapter
    const newContent = [
      ...fullContent.slice(0, h2Index),
      ...fullContent.slice(endIndex + 1),
    ];

    // Update content and regenerate chapters
    setFullContent(newContent);
    const updatedChapters = generateChaptersFromContent(newContent);
    setChapters(updatedChapters);

    // Clear selection if the deleted subchapter was selected
    if (selectedSubChapter?.id === subChapter.id) {
      setSelectedSubChapter(null);
    }
  };

  const getSelectedChapterContent = (chapter: Chapter): Descendant[] => {
    return getChapterContent(fullContent, chapter);
  };

  return (
    <BasePage breadcrumbItems={breadcrumbItems} loading={isLoading}>
      <div className="py-4 max-h-full overflow-y-hidden">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">
            Betriebsanleitung{" "}
            <span className="inline text-sm pl-2 text-cyan-600 cursor-pointer">
              Wiki
            </span>
          </h1>
          <p className="text-sm text-gray-500 block pt-6 w-8/12">
            Eine Betriebsanleitung ist ein zentrales Dokument, das dem Benutzer
            eines Produkts alle notwendigen Informationen für den sicheren und
            bestimmungsgemäßen Gebrauch liefert. Sie erfüllt die Anforderungen
            relevanter CE-Richtlinien wie der Maschinenrichtlinie oder der
            Niederspannungsrichtlinie und ist daher unerlässlich, um die
            Konformität Ihres Produkts sicherzustellen.
          </p>
          <div className="flex justify-end mb-4">
            <button
              onClick={() => handleSaveButton()}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
            >
              Anleitung speichern
            </button>
          </div>
        </div>
        <div
          className="flex gap-6 overflow-y-hidden"
          style={{ maxHeight: "75vh" }}
        >
          <ChapterList
            chapters={chapters}
            selectedChapter={selectedChapter}
            selectedSubChapter={selectedSubChapter}
            onChapterClick={handleChapterClick}
            onSubChapterClick={handleSubChapterClick}
            onDeleteChapter={handleDeleteChapter}
            onDeleteSubChapter={handleDeleteSubChapter}
            onAddNewChapter={handleAddNewChapter}
          />
          <ChapterEditor
            selectedChapter={selectedChapter}
            selectedSubChapter={selectedSubChapter}
            getChapterContent={getSelectedChapterContent}
            onSave={handleSave}
          />
        </div>
      </div>
    </BasePage>
  );
};

export default ManualBuilder;
