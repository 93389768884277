import React, { useState, useRef, useEffect } from "react";
import { Editor, Element as SlateElement, Transforms, Path } from "slate";
import { useSlate } from "slate-react";
import { TableElement, TableRowElement, TableCellElement } from "../../types";

export const TableControls: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        event.target instanceof HTMLElement &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const isInTable = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) =>
        SlateElement.isElement(n) && "type" in n && n.type === "table",
    });
    return !!match;
  };

  const insertTable = () => {
    const table: TableElement = {
      type: "table",
      children: [
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 1" }],
            },
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 2" }],
            },
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 3" }],
            },
          ],
        },
        {
          type: "table-row",
          children: [
            { type: "table-cell", children: [{ text: "Cell 1" }] },
            { type: "table-cell", children: [{ text: "Cell 2" }] },
            { type: "table-cell", children: [{ text: "Cell 3" }] },
          ],
        },
      ],
    };

    Transforms.insertNodes(editor, table);
  };

  const getCurrentTable = () => {
    for (const [node, path] of Editor.nodes(editor, {
      match: (n): n is TableElement =>
        SlateElement.isElement(n) && n.type === "table",
    })) {
      return [node, path] as [TableElement, Path];
    }
    return undefined;
  };

  const insertRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const rowCount = table.children.length;
      const columnCount = (table.children[0] as TableRowElement).children
        .length;

      const newRow: TableRowElement = {
        type: "table-row",
        children: Array(columnCount)
          .fill(0)
          .map(() => ({
            type: "table-cell",
            children: [{ text: "" }],
          })),
      };

      Transforms.insertNodes(editor, newRow, {
        at: [...tablePath, rowCount],
      });
    }
  };

  const removeRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table] = tableEntry;
      if (table.children.length > 1) {
        const [, currentPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-row",
          }) || [];

        if (currentPath) {
          Transforms.removeNodes(editor, { at: currentPath });
        }
      }
    }
  };

  const insertColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      table.children.forEach((row, rowIndex) => {
        const isHeader = rowIndex === 0;
        const newCell: TableCellElement = {
          type: "table-cell",
          isHeader,
          children: [{ text: "" }],
        };

        Transforms.insertNodes(editor, newCell, {
          at: [
            ...tablePath,
            rowIndex,
            (row as TableRowElement).children.length,
          ],
        });
      });
    }
  };

  const removeColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const firstRow = table.children[0] as TableRowElement;
      if (firstRow.children.length > 1) {
        const [, cellPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-cell",
          }) || [];

        if (cellPath) {
          const columnIndex = cellPath[cellPath.length - 1];
          table.children.forEach((_, rowIndex) => {
            Transforms.removeNodes(editor, {
              at: [...tablePath, rowIndex, columnIndex],
            });
          });
        }
      }
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        type="button"
        className={`p-2 ${
          isInTable() ? "text-blue-500" : "text-gray-600"
        } hover:bg-gray-100 rounded`}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          if (!isInTable()) {
            insertTable();
          } else {
            setShowMenu(!showMenu);
          }
        }}
        title={isInTable() ? "Table Options" : "Insert Table"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 18.375V5.625ZM21 9.375A.375.375 0 0 0 20.625 9h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5ZM10.875 18.75a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5ZM3.375 15h7.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375Zm0-3.75h7.5a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 10.875 9h-7.5A.375.375 0 0 0 3 9.375v1.5c0 .207.168.375.375.375Z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {showMenu && isInTable() && (
        <div className="absolute left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1" role="menu">
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                insertRow();
                setShowMenu(false);
              }}
            >
              Add Row
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                removeRow();
                setShowMenu(false);
              }}
            >
              Remove Row
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                insertColumn();
                setShowMenu(false);
              }}
            >
              Add Column
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                removeColumn();
                setShowMenu(false);
              }}
            >
              Remove Column
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
